import {
  ArrayParam,
  BooleanParam,
  encodeQueryParams,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { DecodedValueMap } from "serialize-query-params/src/types";

const params = {
  q: StringParam,
  page: NumberParam,
  ttags: ArrayParam,
  ctags: ArrayParam,
};

const useFilterParams = () => useQueryParams(params);

export const encodeFilterParams = (values: Partial<DecodedValueMap<QueryParamConfigMap>>) =>
  encodeQueryParams(params, values);

export default useFilterParams;
