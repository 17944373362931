import { Box, BoxProps } from "@mui/material";

const CardGrid = ({ sx, minWidth = 250, ...rest }: BoxProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
      gap: "16px",
      ...sx,
    }}
    {...rest}
  />
);

export default CardGrid;
