import yn from "yn";
import { PropsWithChildren, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Link, Stack, Typography as T } from "@mui/material";
import {
  CampaignToolIcon,
  ParticipantSearchToolIcon,
  PlusIcon,
  SurveyToolIcon,
  TaskToolIcon,
} from "~/assets/icons";
import useUser from "~/contexts/user";
import useAppHeader from "~/contexts/header";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { DataBrowserSections } from "~/pages/DataBrowser";
import CardGrid from "~/components/CardGrid";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import useProjects from "rwb/hooks/useProjects";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import ProjectList from "rwb/pages/projects/components/ProjectList";
import useCohorts from "cohorts/hooks/useCohorts";
import CohortList from "cohorts/components/CohortList";
import CreateCohort from "cohorts/components/CreateCohort";

const { VITE_COHORT_EXPLORER_ENABLED, VITE_RWB_ENABLED, VITE_SM_ENABLED } = import.meta.env;

const Section = (props: { title: string; actions?: ReactNode } & PropsWithChildren) => (
  <Box
    component="section"
    sx={{
      pt: 2,
      mb: 6,
    }}
  >
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <T variant="h6" fontSize={[18, 20]} fontWeight={550}>
          {props.title}
        </T>
        <Box display="flex" alignItems="stretch" gap={1}>
          {props.actions}
        </Box>
      </Box>
      {props.children}
    </Container>
  </Box>
);

const WorkbenchWidget = () => {
  const limit = 4;
  const projects = useProjects(limit);
  const loading = projects.isLoading;
  const empty = !loading && !projects.data?.total;

  return (
    <Section
      title="Workbenches"
      actions={
        !empty && (
          <>
            {Number(projects.data?.total) > limit && (
              <Button
                variant="text"
                sx={{ px: 1.5, py: 1 }}
                component={RouterLink}
                to="/workbenches"
              >
                All Workbenches
              </Button>
            )}
            <CreateProject
              callToAction={
                <Button
                  aria-label="Create workbench"
                  variant="text"
                  startIcon={<PlusIcon />}
                  sx={{ height: "auto", px: 1.5, py: 1 }}
                >
                  New
                </Button>
              }
            />
          </>
        )
      }
    >
      <Box mt={2}>
        <ProjectList data={projects} pageSize={limit} showPagination={false} />
      </Box>
    </Section>
  );
};

const DataBrowserWidget = () => {
  return (
    <Section title="Data Browser">
      <Box pt={2}>
        <DataBrowserSections embedded />
      </Box>
    </Section>
  );
};

const StudyManagementWidget = () => {
  const links = [
    {
      url: "/sm/participants",
      icon: <ParticipantSearchToolIcon />,
      title: "Search Participants",
      description:
        "Look up participants to view detailed information about their demographics and study participation.",
    },
    {
      url: "/sm/surveys",
      icon: <SurveyToolIcon />,
      title: "Manage Surveys",
      description: "Build a survey to send to participants.",
    },
    {
      url: "/sm/outreach",
      icon: <CampaignToolIcon />,
      title: "Contact Participants",
      description: "Send surveys or other study-related information to a group of participants.",
    },
    {
      url: "/sm/tasks",
      icon: <TaskToolIcon />,
      title: "Manage Tasks",
      description:
        "Manage tasks related to participants' self-reported cancers, revocations or specific study information.",
    },
  ];

  return (
    <Section title="Study Management">
      <CardGrid sx={{ mt: 2 }}>
        {links.map((link) => (
          <Link key={link.url} component={RouterLink} to={link.url} underline="none">
            <InteractiveCard
              sx={{
                px: 3,
                py: 2.5,
                gap: 1.25,
                height: 200,
                justifyContent: "space-between",
              }}
            >
              <IconContainer color="primary.main">{link.icon}</IconContainer>
              <T variant="body1" fontWeight={500}>
                {link.title}
              </T>
              <T
                variant="body2"
                sx={{
                  opacity: 0.7,
                  flex: 1,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "4",
                }}
              >
                {link.description}
              </T>
            </InteractiveCard>
          </Link>
        ))}
      </CardGrid>
    </Section>
  );
};

const CohortExplorerWidget = () => {
  const limit = 4;
  // TODO: refactor below when backend support for pagination is completed
  const { data: cohorts, isLoading } = useCohorts();
  const empty = !isLoading && cohorts?.length === 0;

  return (
    <Section
      title="Cohort Explorer"
      actions={
        !empty && (
          <>
            {Number(cohorts?.length) > limit && (
              <Button variant="text" sx={{ px: 1.5, py: 1 }} component={RouterLink} to="/cohorts">
                All Cohorts
              </Button>
            )}
            <CreateCohort
              callToAction={
                <LoadingButton
                  variant="text"
                  startIcon={<PlusIcon />}
                  sx={{ height: "auto", px: 1.5, py: 1 }}
                >
                  New
                </LoadingButton>
              }
            />
          </>
        )
      }
    >
      <Box mt={2}>
        <CohortList limit={limit} />
      </Box>
    </Section>
  );
};

const title = "Home";

const Home = () => {
  const user = useUser();
  useDocumentTitle(title);
  useAppHeader({ title });

  const showDb = !!user;
  const showRwb = yn(VITE_RWB_ENABLED) && user?.permissions?.includes("view:workbench-app");
  const showSm = yn(VITE_SM_ENABLED) && user?.permissions?.includes("view:sm-app");
  const showCe =
    yn(VITE_COHORT_EXPLORER_ENABLED) && user?.permissions?.includes("view:cohort-explorer-app");

  return (
    <Stack bgcolor="common.dimmedPageBackground" minHeight="100%">
      {showSm && <StudyManagementWidget />}
      {showRwb && <WorkbenchWidget />}
      {showDb && <DataBrowserWidget />}
      {showCe && <CohortExplorerWidget />}
    </Stack>
  );
};

export default Home;
